import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  isDevMode,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { HttpClientModule } from "@angular/common/http";
import {
  ScreenTrackingService,
  UserTrackingService,
  getAnalytics,
  provideAnalytics,
} from "@angular/fire/analytics";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { Auth, getAuth, provideAuth } from "@angular/fire/auth";
import { getFirestore, provideFirestore } from "@angular/fire/firestore";
import { getFunctions, provideFunctions } from "@angular/fire/functions";
import { getPerformance, providePerformance } from "@angular/fire/performance";
import { getStorage, provideStorage } from "@angular/fire/storage";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTreeModule } from "@angular/material/tree";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { NgxsModule } from "@ngxs/store";
import * as Sentry from "@sentry/angular-ivy";
import { connectAuthEmulator } from "firebase/auth";
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectFunctionsEmulator } from "firebase/functions";
import { connectStorageEmulator } from "firebase/storage";
import { environment } from "../environments";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ActionDialogComponent } from "./core/components/action-dialog/action-dialog.component";
import { BaseComponent } from "./core/components/base/base.component";
import { DashboardRedirectorComponent } from "./core/components/dashboard-redirector/dashboard-redirector.component";
import { QuickStartModule } from "./core/components/quick-start/quick-start.module";
import { UnauthorizedComponent } from "./core/components/unauthorized/unauthorized.component";
import { WrongUserTypeComponent } from "./core/components/wrong-user-type/wrong-user-type.component";
import { AppState } from "./state/app-state/app.state";
import { SecureFileState } from "./state/secure-file-state/secure-file.state";
import { SecureProState } from "./state/secure-pro-state/secure-pro.state";
import { UserState } from "./state/user-state/user.state";
import { QuillModule } from "ngx-quill";
import { EntityDetailsState } from "./state/entity-details-state/entity-details.state";
import { MatChipsModule } from "@angular/material/chips";
import { CollectionState } from "./state/collection-state/collection.state";
import { MatCardModule } from "@angular/material/card";
import { InfoBubbleModule } from "./core/components/info-bubble/info-bubble.module";

const useEmulators = environment.isLocal();

@NgModule({
  declarations: [
    AppComponent,
    ActionDialogComponent,
    BaseComponent,
    DashboardRedirectorComponent,
    UnauthorizedComponent,
    WrongUserTypeComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    MatDividerModule,
    MatTreeModule,
    QuickStartModule,
    MatChipsModule,
    MatCardModule,
    InfoBubbleModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => {
      const auth = getAuth();

      if (useEmulators) {
        connectAuthEmulator(auth, "http://localhost:9099", {
          disableWarnings: true,
        });
        type WindowWithAuth = Window & typeof globalThis & { firebase: { auth: Auth } };
        (window as WindowWithAuth).firebase = { auth };
      }

      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();

      if (useEmulators) {
        connectFirestoreEmulator(firestore, "localhost", 8080);
      }

      return firestore;
    }),
    providePerformance(() => getPerformance()),
    provideStorage(() => {
      const storage = getStorage();

      if (useEmulators) {
        connectStorageEmulator(storage, "127.0.0.1", 9199);
      }

      return storage;
    }),
    provideFunctions(() => {
      const functions = getFunctions();

      if (useEmulators) {
        connectFunctionsEmulator(functions, "localhost", 5001);
      }

      return functions;
    }),
    NgxsModule.forRoot([
      UserState,
      SecureProState,
      SecureFileState,
      AppState,
      EntityDetailsState,
      CollectionState,
    ]),
    NgxsLoggerPluginModule.forRoot({
      disabled: !isDevMode(),
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // Do nothing
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
